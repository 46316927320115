<template>
  <div :class="[labelPrint ? 'print-label' : '']" class="invoice-new print-sheet theme--light">
    <div v-if="invoice && labelPrint" class="label-print">
      <div class="d-flex flex-column align-center justify-space-beetween fill-width fill-height">
        <div class="d-flex fill-width justify-space-between align-center label-header pb-4 text-uppercase font-weight-bold">
          <span>
            NO. PESANAN {{ invoice.invoice.mpl_sn || '#' }}
          </span>
          <span>
            {{ invoice.invoice.refid || '#' }}
          </span>
        </div>
        <div class="fill-width" style="border-bottom: solid 1px #000;height: 1px;" />
        <v-row class="d-flex fill-height align-center justify-center flex-stretch">
          <v-col cols="4" class="d-flex py-4 pr-4">
            <img src="/new-logo-ebrightskin-black.png" class="logo">
          </v-col>
          <v-col cols="8" class="body-2 pl-4 text-right" style="border-left: solid 1px #000;font-size: 14px !important;font-weight: 500!important;">
            <div>
              <div class="">
                <div>
                  <strong>Pengirim :</strong> {{ trx.dropship_name || invoice.company.name }}
                </div>
                <div class="">
                  <span>No HP : {{ trx.dropship_phone || $phone(invoice.company.phone) }}</span>
                </div>
              </div>
            </div>
            <div class="pt-4">
              <div class="">
                <div>
                  <strong>Penerima :</strong> {{ invoice.buyerData.name || invoice.customer.name }}
                </div>
                <div class="">
                  <div>No HP : 0{{ invoice.buyerData.phone || invoice.customer.phone }}</div>
                  <div class="full-address text-uppercase">{{ invoice.customer.address || invoice.customer.address }}</div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <div class="fill-width mb-2" style="border-bottom: solid 1px #000;height: 1px;" />
        <div v-if="invoice.invoice.shipping_awb" class="d-flex align-center justify-center px-4">
          <barcode v-bind:value="invoice.invoice.shipping_awb" />
        </div>
        <div class="fill-width mb-2" style="border-bottom: solid 1px #000;height: 1px;" />
        <div class="fill-width px-2">
          <div style="font-size: 12px !important;font-weight: 500!important;">
            <v-row class="font-weight-bold text-black">
              <v-col cols="5" class="py-2">
                Produk
              </v-col>
              <v-col cols="2" class="py-2">
                SKU
              </v-col>
              <v-col cols="2" class="py-2">
                VARIANT
              </v-col>
              <v-col cols="2" class="py-2">
                SKU
              </v-col>
              <v-col cols="1" class="py-2">
                Qty
              </v-col>
            </v-row>
            <div v-if="invoice.invoice.mpl_sn && invoice.mpl_detail" class="text-black">
              <v-row v-for="(itemList, itemListIndex) in (invoice && invoice.mpl_detail ? invoice.mpl_detail.data[0].item_list : [])" :key="'item-list-' + itemListIndex">
                <v-col cols="5" class="py-1">
                  {{ (itemListIndex + 1) }}. {{ itemList.item_name }}
                </v-col>
                <v-col cols="2" class="py-2">
                  {{ itemList.item_sku || '-' }}
                </v-col>
                <v-col cols="2" class="py-2">
                  {{ itemList.model_name || '-' }}
                </v-col>
                <v-col cols="2" class="py-2">
                  {{ itemList.model_sku || '-' }}
                </v-col>
                <v-col cols="1" class="py-2 text-center">
                  {{ itemList.model_quantity_purchased || '-' }}
                </v-col>
              </v-row>
            </div>
            <div v-else class="text-black">
              <v-row v-for="(itemList, itemListIndex) in CartItems" :key="'item-list2-' + itemListIndex">
                <v-col cols="5" class="py-1">
                  {{ (itemListIndex + 1) }}. {{ itemList.product_name }}
                </v-col>
                <v-col cols="2" class="py-2">
                  {{ itemList.product_sku || '-' }}
                </v-col>
                <v-col cols="2" class="py-2">
                  {{ '-' }}
                </v-col>
                <v-col cols="2" class="py-2">
                  {{ '-' }}
                </v-col>
                <v-col cols="1" class="py-2 text-center">
                  {{ itemList.quantity || '-' }}
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
        <div class="fill-width mt-2 mb-2" style="border-bottom: solid 1px #000;height: 1px;" />
        <div class="d-flex fill-width justify-space-between align-center label-header pa-2 text-uppercase font-weight-bold grey lighten-3 rounded text-uppercase">
          <span class="transparent">
            KURIR : {{ invoice.customer.shipping_courier || '#' }}
          </span>
          <!-- <strong class="text-center transparent">
            NO RESI<br>{{ invoice.invoice.shipping_awb || '#' }}
          </strong> -->
          <span class="text-right transparent" style="width: 35%;">
            <strong class="transparent">COD : </strong> {{ parseInt(invoice.invoice.payment_cod) ? $price(invoice.invoice.totalAmount) : 'Rp0' }}
          </span>
        </div>
        <v-row class="fill-width pt-2 px-2" no-gutters style="font-size: 13px !important;font-weight: 500!important;">
          <v-col cols="6" style="font-size: 13px;">
            <span>Catatan Pembeli</span>
            <div>
              {{ invoice.invoice.notes || '-' }}
            </div>
          </v-col>
          <v-col cols="6" style="font-size: 13px;">
            <span>Catatan Penjual</span>
            <div>
              {{ invoice.invoice.seller_invoice_note || '-' }}
            </div>
          </v-col>
        </v-row>
        <!-- {{ invoice }} -->
      </div>
    </div>
    <div v-else-if="invoice" :class="[!parseInt(trx.sync) ? 'stock-in-trx' : (parseInt(trx.buyer) === parseInt(trx.user) ? (trx.seller_vendor_name ? 'stock-in-purchase' : 'stock-in') : 'stock-out')]" class="invoice-new-area">
      <v-row class="no-gutters">
        <v-col cols="12" class="text-center pt-6 px-4">
          <img src="/new-logo-ebrightskin.png" class="logo">
          <v-row no-gutters class="mt-2">
            <v-col v-if="edit && !parseInt(trx.sync)" cols="12" class="pt-4">
              <div class="d-flex fill-width align-center justify-center pb-4 px-2 grey--text text--darken-2">
                <span class="grey lighten-4 rounded-lg py-2 px-4 caption-small font-weight-bold" style="line-height: 100%;">
                  {{ editInvoice.trx.refid }}
                </span>
              </div>
              <REFID v-model="editInvoice.trx.refid" />
            </v-col>
            <v-col cols="6" class="d-flex flex-column text-left align-end pt-7 pb-2" style="line-height: 100%;">
              <!-- <div class="buble">&sdot;</div> -->
              <div class="d-flex flex-column justify-space-between pr-4 fill-height" style="width: 100%;font-size: 12px;">
                <div v-if="invoice && invoice.invoice && invoice.invoice.trx && invoice.invoice.trx.mpl_sn">
                  SN : {{ invoice.invoice.trx.mpl_sn || '-' }}
                  <div>
                    <template>
                      <v-menu
                        v-if="false"
                        v-model="createdMenu"
                        max-width="290px"
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted"
                            placeholder="Created Date"
                            hide-details
                            outlined
                            light
                            dense
                            class="mb-4"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="editInvoice.trx.created"
                          no-title
                          @input="createdMenu = false, SyncModel()"
                        ></v-date-picker>
                      </v-menu>
                      <span v-else-if="!edit" class="d-flex pb-2">{{ invoice.invoice.date }}</span>
                    </template>
                  </div>
                </div>
                <v-spacer />
                <div class="d-flex flex-column justify-end pr-4">
                  <div v-if="!parseInt(trx.sync)">
                    <strong class="d-flex">{{ invoice.company.name }}</strong>
                    <span class="d-flex">{{ invoice.company.address }}</span>
                    <span class="d-flex pb-2">{{ $phone(invoice.company.phone) }}</span>
                    <template>
                      <span class="d-flex">{{ invoice.invoice.seller_invoice_note || 'Thank\'s for your order :' }}</span>
                    </template>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="6" class="pl-8 pt-7 pb-2 text-right" style="line-height: 100%;">
              <h4 v-if="parseInt(trx.sync)" :class="[!parseInt(trx.sync) ? 'primary--text' : (parseInt(trx.buyer) === parseInt(trx.user) ? (trx.seller_vendor_name ? 'pink--text' : 'blue--text text--darken-2') : 'red--text text--darken-2')]">
                {{ trx.seller_vendor_name ? 'PURCHASE' : 'CARDSTOCK' }} #{{ invoice.invoice.id }}
              </h4>
              <h4 v-else class="primary--text">
                INVOICE #{{ invoice.invoice.id }}
              </h4>
              <div v-if="!parseInt(trx.sync) && trx.refid && !edit" style="font-size: 11px;padding-top: 4px;">
                {{ trx.refid }}
              </div>
              <!-- <div v-if="!parseInt(trx.sync) && edit" class="pt-3 caption black--text" style="line-height: 100%;"> -->
              <div v-if="false" class="pt-3 caption black--text" style="line-height: 100%;">
                <SearchCustomer v-model="editInvoice.trx.buyer" :buyer-data="invoice.buyerData" @updated="ChangeBuyerData
                " />
                <template>
                  <!-- <select
                    v-if="editInvoice.trx.buyer"
                    v-model="MyAddressSelected"
                    class="d-block fill-width mt-2"
                  >
                    <option v-for="(addr, iAddr) in [{ id: 0, name: 'No Address', kecamatan_name: '', city_name: '', province_name: '' }].concat(MyAddress || [])" :key="'addr-' + iAddr" :value="parseInt(addr.id)">
                      {{ addr.kecamatan_name ? (addr.name + ', ' + addr.kecamatan_name + ', ' + addr.city_name + ', ' + addr.province_name) : addr.name }}
                    </option>
                  </select> -->
                  <v-select
                    v-if="editInvoice.trx.buyer"
                    v-model="MyAddressSelected"
                    :items="[{ id: 0, name: 'No Address', kecamatan_name: '', city_name: '', province_name: '' }].concat(MyAddress || [])"
                    :item-text="(v) => {
                      return v.kecamatan_name ? (v.name + ', ' + v.kecamatan_name + ', ' + v.city_name + ', ' + v.province_name) : v.name
                    }"
                    item-value="id"
                    hide-details
                    label="Select Address"
                  />
                </template>
                <div v-if="MyAddressSelected" class="pt-2">
                  <input v-model="shipping_address.name" type="text" placeholder="Customer phone" class="d-block f-width mb-1" @keyup="SyncModel()">
                  <input v-model="shipping_address.phone" type="text" placeholder="Customer phone" class="d-block f-width mb-1" @keypress="$NumberOnly" @keyup="SyncModel()">
                  <textarea v-model="shipping_address.address" placeholder="Customer address" class="d-block f-width" type="text" @keyup="SyncModel()"/>
                </div>
                <div v-else class="pt-3">
                  <!-- :items="area.concat([{ kecamatan_id: 0, id: 0, area_name: 'None' }])" -->
                  <v-autocomplete
                    v-model.number="shipping_address.area"
                    :items="area"
                    :name="Math.random()"
                    :search-input.sync="areaquery"
                    item-value="kecamatan_id"
                    item-text="area_name"
                    autocomplete="disabled"
                    dense
                    clearable
                    hide-details
                    class="mb-2 required"
                    label="District, City, Province"
                  ></v-autocomplete>
                  <div class="mb-2" />
                  <!-- <textarea v-model="shipping_address.address" placeholder="Customer address" class="d-block f-width" type="text" @keyup="SyncModel()" /> -->
                </div>
                <div class="pt-2">
                  <label class="d-flex fill-width align-center">
                    <input v-model="isDropshipShow" type="checkbox" class="mr-1">
                    Send as Dropship
                  </label>
                  <div v-if="isDropshipShow" class="pt-2">
                    <input v-model="editInvoice.trx.dropship_name" type="text" placeholder="Sender Name" class="d-block fill-width mb-2">
                    <input v-model="editInvoice.trx.dropship_phone" type="text" placeholder="Sender Phone" class="d-block fill-width ">
                  </div>
                </div>
                <div class="pt-4">
                  <v-select
                    v-model="editInvoice.trx.shipping_courier"
                    :name="Math.random()"
                    :items="itemsCouriers.filter(r => r.show)"
                    item-text="name"
                    item-value="value"
                    label="Select Courier"
                    autocomplete="disabled"
                    dense
                    small
                    hide-details
                  />
                </div>
              </div>
              <div v-else-if="!parseInt(trx.sync)" class="pt-3 caption black--text" style="line-height: 100%;">
                <div class="pb-1" style="line-height: 120%;">
                  {{ invoice.buyerData.name }}, 0{{ invoice.buyerData.phone }}<br>{{ invoice.buyerData.email }}
                </div>
                <v-divider class="mt-1 mb-2" />
                <div v-if="invoice.customer.name && invoice.customer.phone && invoice.customer.address" style="line-height: 120%;">
                  {{ invoice.customer.name }}<br>
                  {{ invoice.customer.phone }}<br>
                  {{ invoice.customer.address }}
                </div>
                <div v-else style="line-height: 120%;">
                  {{ invoice.customer.address }}
                </div>
              </div>
              <div v-if="!parseInt(trx.sync)" class="pt-1" style="line-height: 120%;font-size: 12px;">
                <div v-if="!edit" style="padding-top: 2px;">
                  {{ invoice.invoice.shippingText }}
                  <div style="padding-top: 2px;">
                    Shipping AWB : <strong>{{ invoice.invoice.shipping_awb }}</strong>
                  </div>
                </div>
              </div>
              <div v-if="!parseInt(trx.sync) && LoadBookingCode(invoice.invoice.booking_code)" style="padding-top: 4px;">
                <v-chip v-if="(LoadBookingCode(invoice.invoice.booking_code).booking_code)" x-small dark color="grey darken-1">
                  {{ (LoadBookingCode(invoice.invoice.booking_code).booking_code) }}
                </v-chip>
                <v-chip v-if="(LoadBookingCode(invoice.invoice.booking_code).order_id)" x-small dark color="grey darken-1" class="ml-1">
                  {{ (LoadBookingCode(invoice.invoice.booking_code).order_id) }}
                </v-chip>
              </div>
              <div v-else-if="parseInt(trx.sync)" class="pt-2 caption-small">
                <div class="font-weight-bold pb-1">
                  <v-chip x-small :color="parseInt(trx.buyer) === parseInt(trx.user) ? (trx.seller_vendor_name ? 'pink' : 'blue darken-2') : 'red darken-2'" dark>
                    {{ parseInt(trx.buyer) === parseInt(trx.user) ? (trx.seller_vendor_name ? 'Purchase' : 'Stock In') : 'Stock Out' }}
                  </v-chip>
                </div>
                <!-- Name : {{ trx.buyer_name }} -->
                <div v-if="trx.dropship_name && parseInt(trx.buyer) === parseInt(trx.user)" class="caption-small">
                  From: {{ trx.dropship_name }}<br>
                  Phone: {{ trx.dropship_phone || '-' }}<br>
                  Notes: {{ trx.dropship_notes }}
                </div>
                <div v-else-if="parseInt(trx.buyer) === parseInt(trx.user)" class="caption-small">
                  from: {{ trx.seller_name || trx.seller_vendor_name }}
                </div>
                <div v-else-if="parseInt(trx.seller) === parseInt(trx.user)" class="caption-small">
                  to: {{ trx.buyer_name }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="pt-4 pb-0 px-4 invoice-new-content">
          <v-divider class="primary" />
          <div>
            <table>
              <thead>
                <tr class="black--text">
                  <th class="text-center">
                    No
                  </th>
                  <th class="text-center">
                    Items
                  </th>
                  <th v-if="!parseInt(trx.sync)" class="pr-4 text-right">
                    Price
                  </th>
                  <th v-if="!parseInt(trx.sync)" class="pr-1 text-right">
                    Sell Price
                  </th>
                  <th class="text-right pr-5">
                    Qty
                  </th>
                  <th v-if="!parseInt(trx.sync)" class="text-right pr-2">
                    Subtotal
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="edit" class="red lighten-5">
                  <td style="vertical-align: middle;text-align: center;">
                    #
                  </td>
                  <td style="vertical-align: middle;">
                    <ProductSearch v-model="newCart.product" :with-package="true" :clearable="false" :unclear="true" :classAdd="'rounded-md'" :placeholder="'Add Product'" />
                  </td>
                  <td class="text-right" style="vertical-align: middle;min-width: 110px;">
                    <Currency
                      :label="'Price'"
                      class-add="mb-0"
                      v-model.number="newCart.price_discount"
                    />
                  </td>
                  <td class="text-center px-1" style="vertical-align: middle;max-width:75px;">
                    <input v-model.number="newCart.quantity" type="text" placeholder="qty" class="text-center" style="width: 100%;min-width:25px;">
                  </td>
                  <td class="text-right px-1 indigo darken-2 rounded-lg" colspan="2" style="vertical-align: middle;">
                    <v-btn
                      depressed
                      block
                      dark
                      color="rounded-lg indigo darken-2 font-weight-bold px-1"
                      style="font-size: 9px;"
                      @click.prevent="AddCart()"
                    >
                      + {{ (parseInt(newCart.price_discount || 0) * parseInt(newCart.quantity || 0)) | price }}
                    </v-btn>
                  </td>
                </tr>
                <tr v-for="(item, iItem) in CartItems" :key="'invoice-' + iItem">
                  <td style="vertical-align: top;text-align: center;">
                    <div :class="[edit ? 'py-2' : '']">
                      {{ (iItem + 1) }}
                    </div>
                  </td>
                  <td :style="{ maxWidth: edit ? '75px' : '200px' }" style="vertical-align: top;">
                    <div :class="[edit ? 'py-2 text-truncate' : '']">
                      {{ item.product_name }}
                    </div>
                    <div v-if="edit && parseInt(item.is_package)">
                      <ul :style="{paddingLeft: edit ? '0px' : '24px', listStyle: edit ? 'none' : ''}">
                        <li
                          v-for="(iPackage, iIPackage) in PackageItems(item.id, item.product)"
                          :key="'trx-list-package-' + iIPackage"
                          :style="edit ? {borderBottom: 'solid 1px #f0f0f0',background:'#f0f0f0',margin:'3px 0px',padding:'3px 7px'} : {}"
                          :class="edit ? ['d-flex f-width align-start justify-space-between'] : []"
                          style="position: relative;line-height: 150%;"
                        >
                          <div class="d-flex f-width justify-space-between align-center">
                            <span :class="[edit ? 'text-truncate' : '']" style="max-width:100%;">{{ iPackage.product_name }}</span>
                            <!-- <span>(x{{ parseInt(iPackage.quantity_in_package) }})</span> {{ parseInt(iPackage.quantity) / parseInt(item.quantity) }} -->
                          </div>
                          <div v-if="edit" style="position: absolute;left: -17px;top:0px;">
                            <v-btn x-small icon color="red" @click.prevent="RemovePackageItem(parseInt(iPackage.id), parseInt(iPackage.product), parseInt(iPackage.product_package_mutation))">
                              <v-icon x-small>
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </div>
                        </li>
                        <li v-if="edit" class="pa-0">
                          <ProductSearch v-model="newPackageProduct" classAdd="rounded-md" :placeholder="'Add to package'" :returned-data="{ package_id: item.id, quantity: parseInt(item.quantity), product_id: parseInt(item.product) }" />
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td v-if="!parseInt(trx.sync)" class="text-left" style="vertical-align: top;position: relative;">
                    <div class="d-flex justify-end fill-width align-center pr-1" :style="{ minWidth: (edit ? 'auto' : '75px'), minHeight: (edit ? '40px' : 'unset') }">
                      {{ (parseInt(item.price)) | price }}
                    </div>
                    <div v-if="edit && parseInt(item.is_package)">
                      <div v-for="(iPackage, iIPackage) in PackageItems(item.id, item.product)" :key="'trx-list-package-price-' + iIPackage" class="" style="background:#f0f0f0;margin: 3px 0px;padding:0px 4px;font-size:10px;">
                        {{ iPackage.price | price }} (x{{ parseInt(iPackage.quantity_in_package) }})
                      </div>
                    </div>
                    <!-- <div v-if="edit" class="d-inline-block align-center pr-1" style="max-width:75px;">
                      <input
                        v-model.number="CartItems[iItem].price_percent"
                        type="number"
                        class="caption-small"
                        placeholder="Discount"
                        style="width: calc(100% - 20px)!important;"
                        @keyup="UpdatePriceDiscount(iItem)"
                        @change="UpdatePriceDiscount(iItem)"
                      > %
                    </div> -->
                  </td>
                  <td v-if="!parseInt(trx.sync)" class="text-right" :style="{minWidth: edit ? '80px' : '45px'}" style="vertical-align: top;">
                    <div v-if="edit">
                      <Currency
                        placeholder="Prices"
                        class-add="mb-0"
                        v-model.number="CartItems[iItem].price_discount"
                        @input="UpdatePriceDiscount(iItem, true)"
                      />
                      <!-- <input
                        v-model.number="CartItems[iItem].price_discount"
                        class="text-right"
                        @keyup="SyncModel()"
                      /> -->
                    </div>
                    <div v-else>
                      <span class="text-left">
                        {{ parseInt(item.price_discount) | price }}
                      </span>
                    </div>
                    <div v-if="edit && parseInt(item.is_package)">
                      <div v-for="(iPackage, iIPackage) in PackageItems(item.id, item.product)" :key="'trx-list-package-price-' + iIPackage" class="" style="background:#f0f0f0;margin: 3px 0px;padding:0px 4px;font-size:10px;">
                        {{ (parseInt(iPackage.price) * parseInt(iPackage.quantity_in_package)) | price }}
                      </div>
                      <div class="text-right" style="margin: 3px 0px;padding:0px 4px;font-size:10px;">
                        Subtotal<br>
                        Discount
                      </div>
                    </div>
                  </td>
                  <td class="text-right pr-7" style="vertical-align: top;">
                    <input
                      v-if="edit"
                      v-model.number="CartItems[iItem].quantity"
                      class="text-center py-2"
                      style="width: 100%;"
                      type="number"
                      @keyup="UpdateQuantity(iItem)"
                      @mouseup="UpdateQuantity(iItem)"
                    />
                    <span v-else>
                      {{ item.quantity }}
                    </span>
                    <div v-if="edit && parseInt(item.is_package)">
                      <div v-for="(iPackage, iIPackage) in PackageItems(item.id, item.product)" :key="'trx-list-package-price-' + iIPackage" class="" style="background:#f0f0f0;margin: 3px 0px;padding:0px 4px;font-size:10px;">
                        {{ item.quantity }}
                      </div>
                    </div>
                  </td>
                  <td v-if="!parseInt(trx.sync)" :colspan="edit ? '2' : ''" class="text-right" style="vertical-align: top;position: relative;">
                    <div class="d-flex justify-end fill-width align-center pr-1" :style="{ minWidth: (edit ? 'unset' : '75px'), minHeight: (edit ? '40px' : 'unset') }">
                      {{ (parseInt(item.price_discount) * parseInt(item.quantity)) | price }}
                    </div>
                    <div v-if="edit && parseInt(item.is_package)">
                      <div v-for="(iPackage, iIPackage) in PackageItems(item.id, item.product)" :key="'trx-list-package-price-' + iIPackage" class="" style="background:#f0f0f0;margin: 3px 0px;padding:0px 4px;font-size:10px;">
                        {{ ((parseInt(iPackage.price) * parseInt(iPackage.quantity_in_package)) * item.quantity) | price }}
                      </div>
                      <div class="text-right" style="background:#f0f0f0;margin: 3px 0px;padding:0px 4px;font-size:10px;">
                        {{ GetPackageSummary(item).total | price }}<br>
                        {{ GetPackageSummary(item).diff | price }}
                      </div>
                    </div>
                    <span v-if="edit" style="position: absolute;top: -1px;right: -20px;">
                      <v-btn icon x-small color="red" @click.prevent="RemoveCart(item)">
                        <v-icon x-small class="">
                          mdi-close-outline
                        </v-icon>
                      </v-btn>
                    </span>
                  </td>
                </tr>
                <tr v-if="!parseInt(trx.sync) && (edit || parseInt(invoice.invoice.price_discount))">
                  <td v-if="!edit" colspan="5" class="subtotal text-right" >
                    <div>
                      <span v-if="invoice.invoice.price_discount">Discount Customer:</span>
                    </div>
                  </td>
                  <td :colspan="edit ? 6 : 1" class="subtotal text-right">
                    <div class="d-inline-block" style="max-width: 200px;">
                      <Currency
                        v-if="edit"
                        v-model.number="trxCounter.price_discount"
                        label="Discount"
                        @input="SyncModel()"
                      />
                      <span v-else class="d-inline-block pr-1">{{ invoice.invoice.price_discount | price }}</span>
                    </div>
                  </td>
                </tr>
                <tr v-if="!parseInt(trx.sync) && (edit || parseInt(invoice.invoice.price_discount))">
                  <td v-if="!edit" colspan="5" class="subtotal text-right">
                    <div>
                      <span>Discount Package:</span>
                    </div>
                  </td>
                  <td :colspan="edit ? 6 : 1" class="subtotal text-right">
                    <div class="d-inline-block" style="max-width: 200px;">
                      <!-- <Currency
                        v-if="edit"
                        v-model.number="trxCounter.price_discount"
                        label="Discount"
                        @input="SyncModel()"
                      /> -->
                      <span class="d-inline-block pr-1">{{ invoice.invoice.discount_package | price }}</span>
                    </div>
                  </td>
                </tr>
<!--                 <tr v-if="!parseInt(trx.sync)">
                  <td colspan="4" class="subtotal bt py-0 text-right pr-8">
                    Subtotal :
                  </td>
                  <td v-if="!edit" class="subtotal bt py-0 text-right" style="visibility: hidden;">
                    <span class="pr-1">{{ price_subtotal | price }}</span>
                  </td>
                  <td :colspan="edit ? 2 : ''" class="subtotal bt py-0 text-right font-weight-bold">
                    <span class="pr-1">{{ price_subtotal - invoice.invoice.price_discount - invoice.invoice.discount_package | price }}</span>
                  </td>
                </tr> -->
                <tr v-if="!parseInt(trx.sync) && (edit || parseInt(invoice.invoice.price_discount_treat))">
                  <td v-if="!edit" colspan="5" class="subtotal text-right">
                    <div>
                      Treat Customer :
                    </div>
                  </td>
                  <td :colspan="edit ? 6 : 1" class="subtotal text-right">
                    <div class="d-inline-block" style="max-width: 200px;">
                      <Currency
                        v-if="edit"
                        v-model.number="editInvoice.trx.price_discount_treat"
                        label="Treat Customer"
                        @input="SyncModel()"
                      />
                      <span v-else class="d-inline-block pr-1">{{ invoice.invoice.price_discount_treat | price }}</span>
                    </div>
                  </td>
                </tr>
                <tr v-if="edit || parseInt(invoice.invoice.shipping)">
                  <td :colspan="edit ? 3 : 5" class="subtotal text-right" style="vertical-align:top;">
                    <!-- <div v-if="edit" class="d-inline-flex align-center">
                      Shipping ({{ (shipping_weight/1000).toLocaleString() }}Kg) :
                      <select v-model="newCart.courierSelected" class="ml-2" style="max-width: 50px;">
                        <option v-for="(sc, iSc) in $store.state.itemsCouriers" :key="'shipping-courier-' + iSc" :value="sc.value">
                          {{ sc.name }}
                        </option>
                      </select>
                    </div> -->
                    <div v-if="edit">
                      {{ editInvoice.trx.courier_name }} {{ (shipping_weight/1000).toLocaleString() }}Kg :
                      <!-- {{ trx.courier_name }} {{ (shipping_weight/1000).toLocaleString() }}Kg ({{ trx.shipping_cost_value | price }}/Kg) : -->
                    </div>
                    <div v-else class="d-inline-block">
                      {{ invoice.invoice.shippingText }} :
                    </div>
                  </td>
                  <td :colspan="edit ? 3 : ''" class="subtotal text-right">
                    <div v-if="edit" style="max-width: 200px;margin: 0 0 auto auto;">
                      <Currency
                        v-model.number="shipping_total"
                        label="Shipping Cost"
                        @input="SyncModel()"
                      />
                    </div>
                    <div v-else class="pr-1">
                      {{ invoice.invoice.shipping | price }}
                    </div>
                  </td>
                </tr>
                <tr v-if="!parseInt(trx.sync)">
                  <td colspan="5" class="total bt bb text-right py-0 pr-1">
                    Total
                  </td>
                  <td class="total bt bb text-right pr-1 py-0" style="min-width: 85px;">
                    <span v-if="edit">{{ (payment_total - editInvoice.trx.price_discount_treat) | price }}</span>
                    <span v-else>{{ invoice.invoice.totalAmount | price }}</span>
                  </td>
                </tr>
                <tr v-if="!parseInt(trx.sync)">
                  <td colspan="5" class="total bb text-right pr-1">
                    Payment Paid
                  </td>
                  <td class="total bb text-right grey lighten-4 pr-1" style="min-width: 85px;">
                    <span>{{ trx.payment_total_paid | price }}</span>
                  </td>
                </tr>
                <!-- <tr v-if="!parseInt(trx.sync)">
                  <td colspan="5" class="total bb text-right pr-1 py-0">
                    Balance
                  </td>
                  <td :class="[(parseInt(trx.payment_total_paid) - parseInt(trx.payment_total)) < 0 ? 'red' : 'green']" class="total bb text-right white--text pr-1 py-0" style="min-width: 85px;">
                    <span class="transparent">{{ (parseInt(trx.payment_total_paid) - parseInt(trx.payment_total)) | price }}</span>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
        </v-col>
        <v-col v-if="(CartMerchandise.length > 0 || edit)" cols="12" class="pt-2 px-4">
          <v-divider />
          <div class="caption pt-2 d-flex fill-width justify-space-between align-center">
            <div class="d-flex flex-wrap align-center">
              <strong class="caption-small">Merchandise :</strong>
              <v-chip v-for="(cm, iCm) in CartMerchandise" :key="'merchandise-' + iCm" small light class="ml-1">
                <v-icon v-if="edit" x-small color="red" class="mr-1 c-pointer" @click.prevent="RemoveCart(cm)">
                  mdi-close-outline
                </v-icon>
                <span>
                  {{ cm.product_name }}
                </span>
                <span v-if="!edit" class="ml-1">
                  ({{ cm.quantity }}),
                </span>
                <input
                  v-else
                  v-model.number="CartMerchandise[iCm].quantity"
                  type="number"
                  class="ml-2"
                  style="max-width: 45px;outline:none;border-radius:0px;"
                  @keypress="$NumberOnly"
                  @keyup="!parseInt(CartMerchandise[iCm].quantity) ? CartMerchandise[iCm].quantity = 1 : '', SyncModel()"
                  @change="!parseInt(CartMerchandise[iCm].quantity) ? CartMerchandise[iCm].quantity = 1 : '', SyncModel()"
                >
              </v-chip>
            </div>
            <div v-if="edit" class="d-flex align-center" style="max-width:200px;">
              <ProductSearch :is-merchandise="true" placeholder="+Merchandise" @input="CART_MERCHANDISE_ADDITIONAL_PROCESS('merchandise', $event)" />
            </div>
          </div>
        </v-col>
        <v-col v-if="(CartAdditional.length > 0 || edit)" cols="12" class="pt-2 px-4">
          <v-divider />
          <div class="caption pt-2 d-flex fill-width justify-space-between align-center">
            <div class="d-flex flex-wrap align-center">
              <strong class="caption-small">Additional Packaging :</strong>
              <v-chip v-for="(cm, iCm) in CartAdditional" :key="'additional-' + iCm" small light class="ml-1">
                <v-icon v-if="edit" x-small color="red" class="mr-1 c-pointer" @click.prevent="RemoveCart(cm)">
                  mdi-close-outline
                </v-icon>
                <span>
                  {{ cm.product_name }}
                </span>
                <span v-if="!edit" class="ml-1">
                  ({{ cm.quantity }}),
                </span>
                <input
                  v-else
                  v-model.number="CartAdditional[iCm].quantity"
                  type="number"
                  class="ml-2"
                  style="max-width: 45px;outline:none;border-radius:0px;"
                  @keypress="$NumberOnly"
                  @keyup="!parseInt(CartAdditional[iCm].quantity) ? CartAdditional[iCm].quantity = 1 : '', SyncModel()"
                  @change="!parseInt(CartAdditional[iCm].quantity) ? CartAdditional[iCm].quantity = 1 : '', SyncModel()"
                >
              </v-chip>
            </div>
            <div v-if="edit" class="d-flex align-center" style="max-width:200px;">
              <ProductSearch :is-additional="true" placeholder="+Additional/Packaging" @input="CART_MERCHANDISE_ADDITIONAL_PROCESS('additional', $event)" />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="!multiple" no-gutters class="px-4">
        <v-col v-if="!parseInt(trx.sync)" cols="12" class="no-print">
          <div class="px-4 py-3 mt-2 grey lighten-4 rounded-0">
            <div class="caption-small font-weight-bold d-flex fill-width justify-space-between align-center">
              Payment
             <!--  <v-chip x-small class="caption-small white--text" color="indigo" dark>
                Deposit : {{ $price(deposit) }}
              </v-chip> -->
            </div>
            <div v-if="parseInt(trx.status) < 4" class="py-1 no-print">
              <div class="d-flex fill-width align-center">
                <div class="d-inline-flex pr-1" style="width: 40%!important;">
                  <v-select
                    v-model.number="t.balanceinout"
                    :class="[parseInt(t.balanceinout) ? 'hide-input' : '']"
                    :items="[
                      // { id: 2, name: 'Deposit' },
                      { id: 1, name: 'Transfer/Cash' }
                    ]"
                    item-value="id"
                    item-text="name"
                    dense
                    label="Source"
                    placeholder="Select Source"
                    hide-details
                    outlined
                    disabled
                  />
                </div>
                <Currency
                  v-model.number="t.amount"
                  :label="'Amount'"
                  class-add="mb-0 ml-1"
                />
              </div>
              <div class="d-flex fill-width align-center pt-2">
                <v-text-field
                  v-model="t.comment"
                  dense
                  label="Notes / Comment"
                  hide-details
                  outlined
                />
                <v-btn outlined class="text-capitalize caption-small font-weight-bold ml-1" dark color="orange darken-2" @click.prevent="BALANCE_PROCESS()">
                  + ADD
                </v-btn>
              </div>
            </div>
            <div v-for="(ph, iPh) in paymentHistory" :key="'payment-' + iPh" class="d-flex fill-width justify-space-between align-center caption-small my-1 pa-2 white rounded-lg">
              <div class="d-flex justify-center align-start flex-column">
                <div class="d-flex align-center" style="line-height:100%;">
                  <div v-if="parseInt(trx.status) < 4" class="mr-1 rounded-circle d-flex align-center justify-center red" style="width: 17px;height:17px;line-height:100%;cursor:pointer;" @click.prevent="BALANCE_DELETE(ph.id)">
                    <v-icon x-small color="white">
                      mdi-close
                    </v-icon>
                  </div>
                  <strong>{{ ph.comment }}</strong>
                  <v-chip v-if="parseInt(ph.balanceinout) === 2" x-small color="indigo" outlined class="ml-1" dark style="font-size: 9px;">
                    Deposit
                  </v-chip>
                </div>
                <div class="pt-1 grey--text text--darken-1" style="line-height:100%;">
                  {{ $localDT(ph.created, 'datetime') }}
                </div>
              </div>
              <div class="font-weight-bold pr-1">
                {{ ph.amount | price }}
              </div>
            </div>
          </div>
        </v-col>
        <v-col cols="12" class="pt-4 pb-3">
          <v-divider class="primary" />
        </v-col>
        <v-col cols="6" :class="[parseInt(trx.seller_vendor) ? 'pb-4' : 'pb-10']" class="pl-0 text-left caption">
          <small>Notes :</small><br>
          <template>
            <textarea v-if="edit" v-model="editInvoice.trx.notes" type="text" class="text-right" @keyup="SyncModel()" />
            <span v-else>{{ invoice.invoice.notes }}</span>
          </template>
        </v-col>
        <v-col v-if="trx.payment_invoice || (invoice.invoice.payment && !parseInt(trx.sync))" :class="[parseInt(trx.seller_vendor) ? 'pb-4' : 'pb-10']" cols="6" class="pr-0 text-right caption">
          <div v-if="!edit && (parseInt(trx.status) === 1 || trx.payment_invoice)" class="no-print">
            <div class="d-flex flex-column align-end pb-2 text-right">
              <v-btn :color="trx.payment_invoice ? 'indigo darken-2' : 'red darken-2'" class="text-capitalize rounded-pill d-flex align-center px-4 mb-2" depressed dark x-small @click.prevent="XenditInvoice()">
                <v-icon x-small class="mr-2">
                  {{ trx.payment_invoice ? 'mdi-credit-card-check-outline' : 'mdi-radiobox-marked' }}
                </v-icon>
                {{ trx.payment_invoice ? 'Open' : 'Create' }} Xendit Invoice
              </v-btn>
              <small v-if="!trx.payment_invoice" class="font-italic" style="line-height: 140%;">
                Customers will be sent a payment invoice link via email, whatsapp, and or sms!
              </small>
              <small v-else class="font-italic" style="line-height: 140%;">
                Customer has been sent a payment link via email, whatsapp, or sms!
              </small>
            </div>
          </div>
          <template>
            <div v-if="edit">
              <small>Payment Bank : <strong><input v-model="payment.bank" type="text" placeholder="Bank Name" class="text-right mb-1" @keyup="SyncModel()"></strong></small><br>
              <input v-model="payment.number" type="text" placeholder="Account Number" class="text-right mb-1" @keyup="SyncModel()"><br>
              <input v-model="payment.name" type="text" placeholder="Account Name" class="text-right" @keyup="SyncModel()">
            </div>
            <div v-else>
              <small>Payment Bank : <strong>{{ invoice.invoice.payment[0] }}</strong></small><br>
              {{ invoice.invoice.payment[1] }}<br>
              {{ invoice.invoice.payment[2] }}
            </div>
          </template>
        </v-col>
        <v-col v-else-if="!parseInt(trx.sync)" cols="6" :class="[parseInt(trx.seller_vendor) ? 'pb-4' : 'pb-16']" class="px-8 text-right caption">
          <small>-</small>
        </v-col>
        <v-col v-if="parseInt(trx.seller_vendor)" cols="12">
          <InvoiceViewPurchase :trx="trx.id" :cart="cart" />
        </v-col>
        <!-- <v-col cols="12">
          <v-divider />
        </v-col> -->
      </v-row>
    </div>
    <div v-if="!labelPrint" class="footer-note">
      <v-row no-gutters>
        <v-col cols="12" class="d-flex pb-10 primary--text text-center justify-center title">
          <h2>&sdot;&sdot;&sdot;</h2>
        </v-col>
        <v-col cols="3" class="d-flex align-center pl-8 pr-4">
          <v-divider />
        </v-col>
        <v-col cols="6">
          eBright Skin - Copyright &copy; {{new Date().getFullYear()}}
        </v-col>
        <v-col cols="3" class="d-flex align-center pr-8 pl-4">
          <v-divider />
        </v-col>
      </v-row>
    </div>
    <slot name="break-point" />
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'

let syncTimeout = null
let searchTimeout = null
export default {
  components: {
    barcode: VueBarcode
  },
  props: {
    labelPrint: {
      type: Boolean,
      default: false
    },
    trx: {
      type: Object,
      default: null
    },
    // cart: {
    //   type: Array,
    //   default: () => []
    // },
    multiple: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => null
    }
  },
  data: (vm) => ({
    cart: [],
    invoice: null,
    editInvoice: {
      trx: null,
      cart: []
    },
    shipping_address: {
      name: '',
      phone: '',
      area: 0,
      address: '',
      kecamatan_id: 0,
      kecamatan_name: '',
      city_name: '',
      province_name: '',
      courier_name: '',
      service_name: '',
      shipping_courier: '',
      shipping_cost_id: 0,
      shipping_cost_value: 0
    },
    payment: {
      bank: '',
      number: '',
      name: ''
    },
    createdMenu: false,
    newPackageProduct: null,
    trxCounter: {
      price_discount: 0
    },
    newCart: {
      product: null,
      price_discount: 0,
      quantity: 1,
      courierSelected: null
    },
    deposit: 0,
    t: {
      id: null,
      balanceinout: 1,
      user: 0,
      trx: 0,
      amount: 0,
      comment: '',
      created: null,
      status: 1
    },
    shipping_total: 0,
    paymentHistory: [],
    MyAddress: [],
    MyAddressSelected: 0,
    areaquery: '',
    area: [],
    isDropshipShow: false,
    itemsCouriers: [
      { value: 'jne', name: 'JNE', show: true },
      { value: 'ninja', name: 'Ninja', show: true },
      { value: 'jnt', name: 'J&T', show: true },
      { value: 'pos', name: 'POS', show: true },
      { value: 'sicepat', name: 'SICEPAT', show: true },
      { value: 'ide', name: 'ID Express', show: true },
      { value: 'cod', name: 'COD / PICKUP', show: true }
    ],
    merchandiseProducts: [],
    additionalProducts: [],
    selectedMerchandiseProduct: 1,
    selectedAdditionalProduct: 6,
    commentSelectedMerchandiseProduct: '',
    commentSelectedAdditionalProduct: '',
    qtySelectedMerchandiseProduct: 0,
    qtySelectedAdditionalProduct: 0
  }),
  computed: {
    RefidParser () {
      const refid = this.editInvoice.trx.refid
      if (refid) {
        const refidSplit = refid.split('/')
        if (refidSplit.length === 5) {
          const d = refidSplit[3]
          const dt = d.substr(4, 2) + '-' + d.substr(2, 2) + '-' + d.substr(0, 2)
          const result = {
            trx_source: refidSplit[0] || '',
            uid: parseInt(refidSplit[1].replace('ADM', '')) || 0,
            user: parseInt(refidSplit[2].replace('EB', '')),
            seller: parseInt(refidSplit[2].replace('EB', '')),
            created: '20' + dt
          }
          return result
        }
      }
      return null
    },
    CartItemsAll () {
      if (this.edit) {
        return this.editInvoice.cart.filter(r => (!parseInt(r.product_package_mutation) && !parseInt(r.removed)))
      } else {
        return this.invoice.invoice.items.filter(r => (!parseInt(r.product_package_mutation) && !parseInt(r.removed)))
      }
    },
    CartItems () {
      if (this.edit) {
        return this.editInvoice.cart.filter(r => (!parseInt(r.product_package_mutation) && !parseInt(r.removed)) && !parseInt(r.is_merchandise) && !parseInt(r.is_additional))
      } else {
        return this.invoice.invoice.items.filter(r => (!parseInt(r.product_package_mutation) && !parseInt(r.removed)) && !parseInt(r.is_merchandise) && !parseInt(r.is_additional))
      }
    },
    CartMerchandise () {
      if (this.edit) {
        return this.editInvoice.cart.filter(r => (!parseInt(r.product_package_mutation) && !parseInt(r.removed)) && parseInt(r.is_merchandise))
      } else {
        return this.invoice.invoice.items.filter(r => (!parseInt(r.product_package_mutation) && !parseInt(r.removed)) && parseInt(r.is_merchandise))
      }
    },
    CartAdditional () {
      if (this.edit) {
        return this.editInvoice.cart.filter(r => (!parseInt(r.product_package_mutation) && !parseInt(r.removed)) && parseInt(r.is_additional))
      } else {
        return this.invoice.invoice.items.filter(r => (!parseInt(r.product_package_mutation) && !parseInt(r.removed)) && parseInt(r.is_additional))
      }
    },
    computedDateFormatted () {
      return this.formatDate(this.editInvoice.trx.created)
    },
    price_subtotal () {
      let subtotal = 0
      const cart = this.cart.filter(r => !parseInt(r.product_package_mutation) && !parseInt(r.removed) && !parseInt(r.is_merchandise) && !parseInt(r.is_additional))
      for (let c = 0; c < cart.length; c++) {
        subtotal += (parseInt(cart[c].price) * parseInt(cart[c].quantity))
      }
      return subtotal
    },
    price_total () {
      let total = 0
      const cart = this.cart.filter(r => (!parseInt(r.product_package_mutation) && !parseInt(r.removed)) && !parseInt(r.is_merchandise))
      for (let c = 0; c < cart.length; c++) {
        total += (parseInt(cart[c].price_discount) * parseInt(cart[c].quantity))
      }
      return total
    },
    shipping_weight () {
      let sw = 0
      const cart = this.CartItemsAll
      for (let c = 0; c < cart.length; c++) {
        sw += (parseInt(cart[c].weight) * parseInt(cart[c].quantity))
      }
      return sw
    },
    payment_total () {
      return (this.price_total - this.trxCounter.price_discount) + this.shipping_total
    }
  },
  watch: {
    isDropshipShow (v) {
      if (!v) {
        this.editInvoice.trx.dropship_name = ''
        this.editInvoice.trx.dropship_phone = ''
      } else {
        this.editInvoice.trx.dropship_name = this.trx.dropship_name || ''
        this.editInvoice.trx.dropship_phone = this.trx.dropship_phone || ''
      }
      this.SyncModel()
    },
    areaquery: function (v) {
      if (!v) {
        return
      }
      const findSelectedArea = this.area.find(r => r.area_name === v)
      if (findSelectedArea) {
        return
      }
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }
      searchTimeout = setTimeout(() => {
        this.areaGet('?q=' + v)
        clearTimeout(searchTimeout)
      }, 300)
    },
    'shipping_address.area' (v) {
      const f = this.area.find(r => parseInt(r.kecamatan_id) === parseInt(v))
      if (f) {
        this.shipping_address.kecamatan_id = f.kecamatan_id || 0
        this.shipping_address.kecamatan_name = f.kecamatan_name || ''
        this.shipping_address.city_name = f.city_name || ''
        this.shipping_address.province_name = f.province_name || ''
      }
      this.SyncModel()
    },
    MyAddressSelected (v) {
      if (!v) {
        this.shipping_address.name = this.invoice.customer.name || null
        this.shipping_address.address = this.invoice.customer.address || null
        this.shipping_address.phone = this.invoice.customer.phone || null

        this.shipping_address.kecamatan_id = this.invoice.customer.kecamatan_to_id || 0
        this.shipping_address.kecamatan_name = this.invoice.customer.kecamatan_to || ''
        this.shipping_address.city_name = this.invoice.customer.city_to || ''
        if (this.shipping_address.name) {
          this.shipping_address.address = ''
        }
      } else {
        const selected = this.MyAddress.find(r => parseInt(r.id) === parseInt(v))
        if (selected) {
          this.shipping_address.name = selected.name || ''
          this.shipping_address.phone = selected.phone || ''
          this.shipping_address.address = selected.address || ''
          this.shipping_address.area = selected.area || 0
          this.shipping_address.kecamatan_id = selected.kecamatan_id || 0
          this.shipping_address.kecamatan_name = selected.kecamatan_name || ''
          this.shipping_address.city_name = selected.city_name || ''
          this.shipping_address.province_name = selected.province_name || ''
        }
      }
      this.SyncModel()
    },
    'editInvoice.trx.refid' (v) {
      if (v) {
        const result = this.RefidParser
        if (result) {
          this.editInvoice.trx.trx_source = result.trx_source
          this.editInvoice.trx.uid = result.uid
          this.editInvoice.trx.user = result.user
          this.editInvoice.trx.seller = result.seller
          this.editInvoice.trx.created = result.created
        }
      }
      this.SyncModel()
    },
    'editInvoice.trx.shipping_courier' (v) {
      if (v) {
        const f = this.itemsCouriers.find(r => r.value === v)
        if (f) {
          this.editInvoice.trx.courier_name = f.name
        }
      }
      this.SyncModel()
    },
    't.balanceinout' (v) {
      if (this.t.balanceinout === 2) {
        if (parseInt(this.t.amount) > parseInt(this.deposit)) {
          this.$store.commit('SNACKBAR', { show: true, text: 'You have no deposit!' })
        }
      }
    },
    't.amount' (v) {
      if (this.t.balanceinout === 2 && (parseInt(v) > this.deposit)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Insufficient deposit amount!' })
      }
    },
    'newCart.product' (v) {
      if (v) {
        this.newCart.price_discount = parseInt(v.price) || 0
      }
    },
    async edit (v) {
      // await this.CartReset()
      if (v) {
        this.editInvoice.cart = [].concat(this.cart || []).map(r => {
          r.price_percent = 100 - ((parseInt(r.price_discount) * 100) / parseInt(r.price))
          r.removed = 0
          return r
        })
        await this.PrepareEdit()
        this.BALANCE(this.editInvoice.trx.buyer)
        this.BALANCE_GET()
      } else {
        await this.Init()
      }
    },
    'editInvoice.trx.created' (v) {
      // console.log(v)
    },
    newPackageProduct (v) {
      if (v) {
        const existCart = this.editInvoice.cart.find(
          r => (parseInt(r.product) === parseInt(v.id)) &&
            (
              parseInt(r.product_package_mutation) === parseInt(v.returned_data ? v.returned_data.package_id : 0) ||
              (!r.id && (parseInt(r.product_package_mutation) === parseInt(v.returned_data ? v.returned_data.product_id : 0)))
            )
        )
        if (existCart) {
          let inputedQuantity = parseInt(existCart.quantity)
          if (!parseInt(existCart.removed)) {
            inputedQuantity = prompt('Update product quantity in package :', (parseInt(existCart.quantity) / parseInt(v.returned_data ? v.returned_data.quantity : 1)))
            inputedQuantity = parseInt(inputedQuantity) ? (parseInt(inputedQuantity) * parseInt(v.returned_data ? v.returned_data.quantity : 1)) : parseInt(existCart.quantity)
          }
          this.editInvoice.cart = this.editInvoice.cart.map(r => {
            if (
              parseInt(r.product) === parseInt(existCart.product) && (parseInt(r.product_package_mutation) === parseInt(existCart.product_package_mutation))
            ) {
              r.quantity = inputedQuantity
              r.removed = 0
            }
            return r
          })
        } else if (v.returned_data) {
          if (parseInt(v.returned_data.package_id) || parseInt(v.id)) {
            const inputedQuantity = prompt('Input Quantity (per-package) :', 1)
            if (parseInt(inputedQuantity) > 0) {
              const d = {
                id: null,
                trx: this.editInvoice.trx.id,
                user: this.editInvoice.trx.user,
                seller: this.editInvoice.trx.seller,
                buyer: this.editInvoice.trx.buyer,
                uid: this.editInvoice.trx.uid,
                sync: this.editInvoice.trx.sync,
                product: parseInt(v.id) || 0,
                product_name: v.name,
                product_package_mutation: parseInt(v.returned_data.package_id) || parseInt(v.returned_data.product_id),
                quantity: parseInt(inputedQuantity) * parseInt(v.returned_data.quantity),
                quantity_in_package: (parseInt(v.returned_data.package_id) || parseInt(v.returned_data.product_id)) ? parseInt(inputedQuantity) : 0,
                price: parseInt(v.price),
                status: 1,
                price_discount: 0,
                price_buy: 0,
                weight: 0,
                is_package: 0,
                removed: 0
              }
              this.editInvoice.cart.push(d)
            }
          } else {
            this.$store.commit('SNACKBAR', { show: true, text: 'Invalid product package selected!' })
          }
        }
        this.SyncModel()
      }
    }
  },
  mounted () {
    this.Init()
    this.listMerchandiseAdditionalProducts(1, 0)
    this.listMerchandiseAdditionalProducts(0, 1)
  },
  methods: {
    listMerchandiseAdditionalProducts (isMerchandise, isAdditional) {
      this.$store.dispatch('PRODUCT_GET', '?is_merchandise=' + isMerchandise + '&is_additional=' + isAdditional).then(res => {
        if (res.status) {
        }
      })
    },
    areaGet (q, id, kecamatanID) {
      this.$store.dispatch('AREA_GET', kecamatanID ? '?kecamatan_id=' + kecamatanID : q + (parseInt(id) ? '&id=' + id : '')).then(res => {
        if (res.status) {
          this.area = res.data.data || []
          if (this.area.length === 1 && q) {
            if (id) {
              this.shipping_address.area = id
            } else {
              const qq = q ? q.replace('?q=', '') : q
              if (qq.split(',').length === 3 && qq.length > 10) {
                this.shipping_address.area = this.area[0].kecamatan_id
                this.areaquery = qq
              }
            }
          }
        }
        // this.SyncModel()
      })
    },
    ChangeBuyerData (data) {
      this.MyAddressSelected = 0
      this.MyAddress = []
      if (data) {
        this.editInvoice.trx.buyer = parseInt(data.id)
        if (parseInt(data.id)) {
          this.editInvoice.cart.map(r => {
            r.buyer = parseInt(data.id)
            return r
          })
        }
        this.shipping_address.kecamatan_id = data.kecamatan_id || 0
        this.shipping_address.kecamatan_name = data.kecamatan_name || ''
        this.shipping_address.city_name = data.city_name || ''
        this.shipping_address.province_name = data.province_name || ''
        this.MyAddress = data.address_data
        const selected = data.address_data.find(r => r.address === this.invoice.customer.address)
        if (selected) {
          this.MyAddressSelected = parseInt(selected.id)
          this.shipping_address.area = selected.area || 0
          if (selected.area) {
            this.areaGet('?q=', selected.area)
          }
        } else {
          this.shipping_address.address = this.trx.shipping_address
          const x = this.shipping_address.address
          const s = (x ? x.split('###').join('') : '').trim()
          if (s) {
            if (s.split('||').length < 3) {
              this.areaquery = s
            }
          }
        }
      }
      this.SyncModel()
    },
    async Init () {
      this.BALANCE(this.trx.buyer)
      this.BALANCE_GET()
      await this.CartReset()
      // document.body.setAttribute('class', 'A5')
      this.editInvoice.trx = Object.assign({}, this.trx)
      this.editInvoice.cart = [].concat(this.cart || []).map(r => {
        r.quantity_in_package = 0
        if (parseInt(r.product_package_mutation)) {
          const findQtyMasterPackage = this.cart.find(x => parseInt(x.id) === parseInt(r.product_package_mutation))
          if (findQtyMasterPackage) {
            r.quantity_in_package = parseInt(r.quantity) / findQtyMasterPackage.quantity
          }
        }
        r.price_percent = 100 - ((parseInt(r.price_discount) * 100) / parseInt(r.price))
        r.removed = 0
        return r
      })
      this.BindInvoice(this.trx, this.cart || [])
      this.t.id = null
      this.t.balanceinout = 1
      this.t.user = 0
      this.t.trx = 0
      this.t.amount = 0
      this.t.comment = ''
      this.t.created = null
      this.t.status = 1
    },
    GetPackageSummary (item) {
      let total = 0
      const cartPackages = this.PackageItems(item.id, item.product)
      for (let t = 0; t < cartPackages.length; t++) {
        total += ((parseInt(cartPackages[t].price) * parseInt(cartPackages[t].quantity_in_package)) * item.quantity)
      }
      const diff = total - (parseInt(item.price_discount) * parseInt(item.quantity))
      return {
        total,
        diff
      }
    },
    UpdatePriceDiscount (index, toPercent) {
      if (toPercent) {
        const c = this.CartItems[index]
        const price = parseInt(c.price)
        const priceDiscount = parseInt(c.price_discount)
        this.CartItems[index].price_percent = (priceDiscount * 100) / price
      } else {
        const price = parseInt(this.CartItems[index].price)
        const percent = parseFloat(parseFloat(this.CartItems[index].price_percent).toFixed(1))
        if (percent) {
          this.CartItems[index].price_discount = price - ((percent / 100) * price)
        } else {
          this.CartItems[index].price_discount = price
        }
        if (!parseInt(this.CartItems[index].price_percent)) {
          this.CartItems[index].price_percent = 0
        } else if (parseInt(this.CartItems[index].price_percent) > 100) {
          this.CartItems[index].price_percent = 100
        }
      }
      this.SyncModel()
    },
    BALANCE_PROCESS () {
      if (parseInt(this.t.amount) < 1000) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Invalid amount!' })
        return false
      }
      if (parseInt(this.t.balanceinout) === 2 && (parseInt(this.t.amount) > this.deposit)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Insufficient deposit amount!' })
        this.BALANCE(this.trx.buyer)
        return false
      }
      // let existAmount = parseInt(this.t.amount)
      // for (let t = 0; t < this.paymentHistory.length; t++) {
      //   existAmount += parseInt(this.paymentHistory[t].amount)
      // }
      // const leftAmount = (parseInt(this.trx.payment_total) - parseInt(existAmount))
      // if (leftAmount < 0) {
      //   this.$store.commit('SNACKBAR', { show: true, text: 'Max amount is ' + (this.$price(parseInt(existAmount) - parseInt(this.t.amount))) })
      //   return false
      // }
      if (!this.t.comment) {
        if (!confirm('Without comment or notes ?')) {
          return false
        }
      }
      this.$store.dispatch('BALANCE_PROCESS', {
        id: this.t.id || null,
        balanceinout: parseInt(this.t.balanceinout) || 1,
        user: this.trx.buyer,
        trx: this.trx.id,
        amount: parseInt(this.t.amount),
        comment: this.t.comment,
        created: null,
        status: 1
      }).then((res) => {
        this.BALANCE_GET()
        this.BALANCE(this.trx.buyer)
        this.$emit('reload', this.trx.id)
        this.t.amount = 0
        this.t.balanceinout = 1
        this.t.comment = ''
        this.t.id = null
        this.SyncModel()
      })
    },
    BALANCE_GET () {
      this.$store.dispatch('BALANCE_GET', {
        trx: this.trx.id,
        user: this.trx.buyer,
        inout: 0,
        page: 1
      })
        .then((res) => {
          if (res.status) {
            this.paymentHistory = res.data.data || []
          }
        })
    },
    BALANCE (user) {
      this.$store.dispatch('BALANCE', user)
        .then((res) => {
          if (res.status) {
            this.deposit = isNaN(parseInt(res.data.data)) ? 0 : parseInt(res.data.data) || 0
          }
        })
    },
    BALANCE_DELETE (bid) {
      if (!confirm('Delete payment ?')) {
        return false
      }
      this.$store.dispatch('BALANCE_DELETE', bid)
        .then(() => {
          this.BALANCE_GET()
          this.BALANCE(this.trx.buyer)
          this.$emit('reload', this.trx.id)
          this.SyncModel()
        })
    },
    UpdateQuantity (i) {
      const getCart = this.CartItems[i]
      if (!parseInt(getCart.quantity)) {
        this.CartItems[i].quantity = 1
      }
      if (parseInt(getCart.is_package) === 1) {
        for (let a = 0; a < this.editInvoice.cart.length; a++) {
          if (parseInt(this.editInvoice.cart[a].product_package_mutation) === parseInt(getCart.id) || parseInt(this.editInvoice.cart[a].product_package_mutation) === parseInt(getCart.product)) {
            this.editInvoice.cart[a].quantity = parseInt(getCart.quantity) * parseInt(this.editInvoice.cart[a].quantity_in_package)
          }
        }
      }
      this.SyncModel()
    },
    XenditInvoice () {
      if (this.trx.payment_invoice) {
        const a = document.createElement('a')
        a.setAttribute('href', this.trx.payment_invoice)
        a.setAttribute('target', '_blank')
        a.click()
      } else {
        if (!confirm('Are you sure want to create xendit invoice ?')) {
          return false
        }
        this.$emit('create-xendit-invoice', this.trx)
      }
    },
    async CartReset () {
      await this.$store.dispatch('CART_TRX_GET', this.trx.id).then((res) => {
        if (res.status) {
          this.cart = [].concat(res.data.data || []).map(r => {
            r.removed = 0
            return r
          })
        }
      })
    },
    RemoveCart (item) {
      if (!item) {
        return false
      } else if (item.id) {
        this.editInvoice.cart = this.editInvoice.cart.map(r => {
          if (parseInt(r.id) === parseInt(item.id) && parseInt(r.product) === parseInt(item.product) && !parseInt(r.product_package_mutation)) {
            r.removed = 1
          }
          return r
        })
      } else {
        const findIndex = this.editInvoice.cart.findIndex(r => !parseInt(r.id) && parseInt(r.product) === parseInt(item.product) && !parseInt(r.product_package_mutation))
        if (findIndex > -1) {
          this.editInvoice.cart.splice(findIndex, 1)
        }
      }
      this.SyncModel()
    },
    async CART_MERCHANDISE_ADDITIONAL_PROCESS (type, product) {
      if (product) {
        if (!parseInt(product.id)) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Invalid Product Selected!' })
          return false
        }
      } else {
        return false
      }
      const existCart = this.editInvoice.cart.find(r => parseInt(r.product) === parseInt(product.id) && parseInt(r['is_' + type]))
      if (existCart) {
        this.editInvoice.cart = this.editInvoice.cart.map(r => {
          r.removed = parseInt(r.product) === parseInt(product.id) && parseInt(r['is_' + type]) ? 0 : (parseInt(r.removed) || 0)
          return r
        })
      } else {
        const newCart = {
          id: null,
          trx: this.editInvoice.trx.id,
          user: this.editInvoice.trx.user,
          seller: this.editInvoice.trx.seller,
          buyer: this.editInvoice.trx.buyer,
          uid: this.editInvoice.trx.uid || 0,
          sync: 0,
          product: parseInt(product.id),
          product_name: product.name,
          product_package_mutation: 0,
          quantity: 1,
          quantity_in_package: 0,
          price: 0,
          price_discount: 0,
          price_buy: 0,
          weight: parseInt(product.weight) || 0,
          is_package: 0,
          is_merchandise: type === 'merchandise' ? 1 : 0,
          is_additional: type === 'additional' ? 1 : 0,
          status: 1,
          removed: 0
        }
        this.editInvoice.cart.push(newCart)
      }
      this.SyncModel()
      // this.$store.dispatch('CART_ADD', newCart).then((res) => {
      //   if (res.status) {
      //     this.$emit('reload', true)
      //   } else {
      //     this.$store.commit('SNACKBAR', { show: true, text: 'Something went wrong' })
      //   }
      // })
    },
    async AddCart () {
      const v = this.newCart.product
      if (!v || !parseInt(this.newCart.quantity)) {
        this.$store.commit('SNACKBAR', { show: true, text: 'Invalid quantity!' })
        return false
      }
      if (v) {
        if (parseInt(v.price) < parseInt(this.newCart.price_discount)) {
          this.$store.commit('SNACKBAR', { show: true, text: 'Ops, invalid Price! Maximum price = ' + this.$price(v.price) })
          return false
        }
        const existCart = this.editInvoice.cart.find(r => parseInt(r.product) === parseInt(v.id) && !parseInt(r.product_package_mutation) && !parseInt(r.is_merchandise) && !parseInt(r.is_additional))
        if (existCart) {
          if (!parseInt(existCart.removed)) {
            if (!confirm('Product already exist! Overwrite data ?')) {
              return false
            }
          }
          let defaultPackageItems = []
          if (v.is_package) {
            defaultPackageItems = await this.GetPackageItems(v.id)
          }
          this.editInvoice.cart = this.editInvoice.cart.map(r => {
            if (parseInt(r.product) === parseInt(existCart.product) && !parseInt(r.product_package_mutation)) {
              r.quantity = parseInt(this.newCart.quantity)
              r.price_discount = parseInt(this.newCart.price_discount)
              r.removed = 0
            } else if (
              parseInt(v.is_package) &&
              (
                (parseInt(existCart.id) === parseInt(r.product_package_mutation)) || (!r.id && (parseInt(existCart.product) === parseInt(r.product_package_mutation)))
              )
            ) {
              // Sync Package Items Quantity
              const defaultQty = defaultPackageItems.find(x => parseInt(x.product) === parseInt(r.product))
              r.quantity = parseInt(this.newCart.quantity) * (defaultQty ? (parseInt(defaultQty.quantity) || parseInt(this.newCart.quantity)) : parseInt(this.newCart.quantity))
            }
            return r
          })
        } else if (!existCart) {
          const inputedQuantity = parseInt(this.newCart.quantity)
          const d = {
            id: null,
            trx: this.editInvoice.trx.id,
            user: this.editInvoice.trx.user,
            seller: this.editInvoice.trx.seller,
            buyer: this.editInvoice.trx.buyer,
            uid: this.editInvoice.trx.uid || 0,
            sync: 0,
            product: parseInt(v.id),
            product_name: v.name,
            product_package_mutation: 0,
            quantity: parseInt(inputedQuantity),
            quantity_in_package: 0,
            price: parseInt(v.price),
            price_discount: parseInt(this.newCart.price_discount),
            price_buy: parseInt(v.price_hpp) || 0,
            weight: parseInt(v.weight) || 0,
            is_package: parseInt(v.is_package) || 0,
            is_merchandise: 0,
            status: 1,
            removed: 0
          }
          this.editInvoice.cart.push(d)
          let defaultPackageItems = []
          if (v.is_package) {
            defaultPackageItems = await this.GetPackageItems(v.id)
            for (let p = 0; p < defaultPackageItems.length; p++) {
              this.editInvoice.cart.push({
                id: null,
                trx: this.editInvoice.trx.id,
                user: this.editInvoice.trx.user,
                seller: this.editInvoice.trx.seller,
                buyer: this.editInvoice.trx.buyer,
                uid: this.editInvoice.trx.uid,
                sync: this.editInvoice.trx.sync,
                product: defaultPackageItems[p].product,
                product_name: defaultPackageItems[p].name,
                product_package_mutation: defaultPackageItems[p].product_package,
                quantity: parseInt(inputedQuantity) * parseInt(defaultPackageItems[p].quantity),
                quantity_in_package: parseInt(inputedQuantity),
                price: defaultPackageItems[p].price,
                status: 1,
                price_discount: 0,
                price_buy: parseInt(defaultPackageItems[p].price_hpp) || 0,
                weight: 0,
                is_package: 0,
                removed: 0
              })
            }
          }
        }
        // this.$store.dispatch('PRODUCT_PACKAGE_GET', 0)
        this.SyncModel()
      } else {
        this.$store.commit('SNACKBAR', { show: true, text: 'No product selected!' })
      }
      this.SyncModel()
    },
    async GetPackageItems (pid) {
      return await this.$store.dispatch('PRODUCT_PACKAGE_GET', pid)
        .then((res) => {
          if (res.status) {
            return res.data.data || []
          }
          return []
        })
    },
    SyncModel () {
      // this.newCart.product = null
      this.newCart.price_discount = 0
      this.newCart.quantity = 1
      if (syncTimeout) {
        clearTimeout(syncTimeout)
      }
      syncTimeout = setTimeout(() => {
        const updatedData = Object.assign({}, this.editInvoice)
        updatedData.trx.shipping_weight = this.shipping_weight
        updatedData.trx.shipping_total = this.shipping_total
        updatedData.trx.price_total = this.price_total
        updatedData.trx.payment_total = this.payment_total - (parseInt(updatedData.trx.price_discount_treat) || 0)
        updatedData.trx.price_discount = this.trxCounter.price_discount || 0
        if (this.MyAddressSelected) {
          updatedData.trx.shipping_address = (this.shipping_address.name || '-') + '||' + (this.shipping_address.phone || '-') + '||' + (this.shipping_address.address || '-') + '||' + this.trx.city_to
        } else if (this.shipping_address.area) {
          const f = this.area.find(r => parseInt(r.kecamatan_id) === parseInt(this.shipping_address.area))
          if (f) {
            updatedData.trx.shipping_address = '### ' + f.area_name
          } else {
            updatedData.trx.shipping_address = ''
          }
        } else {
          updatedData.trx.shipping_address = ''
        }
        updatedData.trx.payment_bank = (this.payment.bank || '-') + ',' + (this.payment.number || '-') + ',' + (this.payment.name || '-')

        updatedData.trx.courier_name = this.shipping_address.courier_name

        // name: '',
        // phone: '',
        // area: 0,
        // address: '',
        // kecamatan_id: 0,
        // kecamatan_name: '',
        // city_name: '',
        // province_name: '',
        // courier_name: '',
        // service_name: '',
        // shipping_courier: '',
        // shipping_cost_id: 0,
        // shipping_cost_value: 0

        this.$emit('input', updatedData)
      }, 500)
    },
    PackageItems (pkgid, pid) {
      if (this.edit) {
        return this.editInvoice.cart.filter(r => (
          !parseInt(r.removed) &&
          (
            parseInt(r.product_package_mutation) === parseInt(pkgid) || (!r.id && parseInt(r.product_package_mutation) === parseInt(pid))
          )
        ))
      } else {
        return this.invoice.invoice.items.filter(r => parseInt(r.product_package_mutation) === parseInt(pkgid))
      }
    },
    RemovePackageItem (itemid, productID, packageID) {
      if (parseInt(itemid)) {
        this.editInvoice.cart = this.editInvoice.cart.map(r => {
          if (parseInt(r.id) === parseInt(itemid) && parseInt(r.product) === parseInt(productID) && parseInt(r.product_package_mutation) === parseInt(packageID)) {
            r.removed = 1
          }
          return r
        })
      } else {
        const findIndex = this.editInvoice.cart.findIndex(r => !parseInt(r.id) && parseInt(r.product) === parseInt(productID) && parseInt(r.product_package_mutation) === parseInt(packageID))
        if (findIndex > -1) {
          this.editInvoice.cart.splice(findIndex, 1)
        }
      }
      this.SyncModel()
    },
    PrepareEdit () {
      this.isDropshipShow = false
      if (this.editInvoice.trx.dropship_name) {
        this.isDropshipShow = true
      }
      this.editInvoice.trx.created = new Date(this.trx.created).toISOString().substr(0, 10)
      this.editInvoice.trx.price_discount_treat = this.invoice.invoice.price_discount_treat

      this.shipping_address.name = this.invoice.customer.name || null
      this.shipping_address.address = this.invoice.customer.address || null
      this.shipping_address.phone = this.invoice.customer.phone || null

      this.shipping_address.kecamatan_id = this.invoice.customer.kecamatan_to_id || 0
      this.shipping_address.kecamatan_name = this.invoice.customer.kecamatan_to || ''
      this.shipping_address.city_name = this.invoice.customer.city_to || ''

      this.shipping_address.courier_name = this.invoice.customer.courier_name
      this.shipping_address.service_name = this.invoice.customer.service_name
      this.shipping_address.shipping_courier = this.invoice.customer.shipping_courier // jnt, jne, etc
      this.shipping_address.shipping_cost_id = this.invoice.customer.shipping_cost_id // cost id
      this.shipping_address.shipping_cost_value = this.invoice.customer.shipping_cost_value // price/kg

      this.payment.bank = this.invoice.invoice.payment ? this.invoice.invoice.payment[0] : ''
      this.payment.number = this.invoice.invoice.payment ? this.invoice.invoice.payment[1] : ''
      this.payment.name = this.invoice.invoice.payment ? this.invoice.invoice.payment[2] : ''

      this.trxCounter.price_discount = parseInt(this.trx.price_discount)
      this.newCart.courierSelected = this.trx.shipping_courier
    },
    BindInvoice (trx, cart) {
      this.MyAddress = []
      this.MyAddressSelected = 0
      const item = Object.assign({}, trx)
      const d = [].concat(cart || [])
      this.invoice = Object.assign({}, {})
      this.invoice.buyerData = Object.assign({}, {
        id: trx.buyer,
        name: trx.buyer_name,
        phone: trx.buyer_phone,
        email: trx.buyer_email,
        address_data: []
      })
      this.invoice.customer = Object.assign({}, {})
      this.invoice.customer.address = item.shipping_address
      if (item.shipping_address) {
        const address = item.shipping_address ? item.shipping_address.split('||') : []
        if (address.length > 3) {
          this.invoice.customer.name = address[0]
          this.invoice.customer.phone = address.length ? address[1] : '-'
          this.invoice.customer.address = address.length ? address[2] : '-'
          this.invoice.customer.address_city = address.length ? (item.city_to || address[3]) : '-'
        } else {
          this.invoice.customer.name = ''
          this.invoice.customer.phone = ''
          this.invoice.customer.address = item.shipping_address
          this.invoice.customer.address_city = ''
        }
      }
      this.invoice.mpl_detail = this.$StrToJson(item.mpl_detail)
      this.invoice.customer.city_to = item.city_to
      this.invoice.customer.kecamatan_to = item.kecamatan_to
      this.invoice.customer.kecamatan_to_id = item.kecamatan_to_id
      this.invoice.customer.courier_name = item.courier_name
      this.invoice.customer.service_name = item.service_name
      this.invoice.customer.shipping_courier = item.shipping_courier // jnt, jne, etc
      this.invoice.customer.shipping_cost_id = item.shipping_cost_id // cost id
      this.invoice.customer.shipping_cost_value = item.shipping_cost_value // price/kg

      this.invoice.invoice = Object.assign({}, {})
      this.invoice.invoice.id = item.id
      this.invoice.invoice.refid = item.refid || null
      this.invoice.invoice.mpl_sn = item.mpl_sn
      this.invoice.invoice.payment_cod = parseInt(item.payment_cod) || 0
      this.invoice.invoice.date = this.$localDT(item.created, 'daydate')
      this.invoice.invoice.price_discount_treat = parseInt(item.price_discount_treat) || 0
      this.invoice.invoice.totalAmount = parseInt(item.payment_total)
      this.invoice.invoice.notes = item.notes || '-'
      this.invoice.invoice.seller_invoice_note = item.seller_invoice_note

      this.invoice.invoice.payment = item.payment_bank.split(',') || null
      this.invoice.invoice.booking_code = item.booking_code || ''
      let subtotalBeforeDiscount = 0
      this.invoice.invoice.items = []
      for (let g = 0; g < d.length; g++) {
        const r = Object.assign({}, d[g])

        r.description = r.product_name
        r.price = parseInt(r.price)
        r.quantity = parseInt(r.quantity)
        if (!parseInt(r.is_merchandise)) {
          subtotalBeforeDiscount += (parseInt(r.price_discount) || parseInt(r.price)) * r.quantity
        }
        this.invoice.invoice.items.push(r)
      }
      // console.log(this.editInvoice.trx)
      // return
      // /* eslint-disable */
      this.invoice.invoice.subtotal = subtotalBeforeDiscount
      this.invoice.invoice.price_discount = parseInt(item.price_discount)
      if (parseInt(this.trx.sync)) {
        this.invoice.invoice.discount = ((subtotalBeforeDiscount) - parseInt(item.payment_total)) - (((subtotalBeforeDiscount) - parseInt(item.payment_total)) * 2)
      } else {
        this.invoice.invoice.discount = ((subtotalBeforeDiscount + parseInt(item.shipping_total)) - parseInt(item.payment_total)) - (((subtotalBeforeDiscount + parseInt(item.shipping_total)) - parseInt(item.payment_total)) * 2)
      }
      this.invoice.invoice.discount_package = parseInt(item.discount_package) || 0
      this.invoice.invoice.shipping = item.shipping_total
      this.invoice.invoice.shippingText = 'Shipping ' + item.courier_name + ' - ' + (item.shipping_weight / 1000).toLocaleString() + 'kg'
      if (item.courier_name && item.shipping_awb) {
        this.invoice.invoice.shippingText = 'Shipping ' + item.courier_name + ' (' + item.shipping_awb + ') ' + (item.shipping_weight / 1000).toLocaleString() + 'kg'
      } else if (!item.courier_name && item.shipping_courier) {
        this.invoice.invoice.shippingText = 'Shipping ' + item.shipping_courier
      }
      if (item.service_name) {
        this.invoice.invoice.shippingText += ' (' + item.service_name + ')'
      }
      if (item.shipping_courier === 'cod') {
        this.invoice.invoice.shippingText = ' COD / PICKUP'
      }
      if (item.shipping_awb) {
        this.invoice.invoice.shipping_awb = item.shipping_awb
      }

      this.shipping_total = parseInt(item.shipping_total) || 0

      this.invoice.company = Object.assign({}, {})
      this.invoice.company.name = item.dropship_name || item.seller_name
      this.invoice.company.phone = item.dropship_phone || item.seller_phone
      this.invoice.company.address = item.kecamatan_from ? item.kecamatan_from + ', ' + item.city_from : '-'
    },
    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    loadShipping () {
      this.loadingShipping = true
      let origin = parseInt(this.$store.state.user.lead_origin)
      if (this.cartBuyerSelected) {
        origin = parseInt(this.$store.state.user.district)
      }
      const s = this.itemsCouriers.filter(r => r.show)
      if (!s.find(r => r.value === this.courierSelected)) {
        if (s.length) {
          this.courierSelected = s[0].value
        } else {
          this.courierSelected = ''
          this.shippingSelected = 0
          return false
        }
      }
      if ((parseInt(this.originSelected) || origin) && parseInt(this.addressSelected.kecamatan_id)) {
        this.$store.dispatch('SHIPPING_CHECK', {
          kecamatan_from: parseInt(this.originSelected) || origin,
          kecamatan_to: parseInt(this.addressSelected.kecamatan_id),
          courier: this.courierSelected || 'jne'
        }).then((res) => {
          this.loadingShipping = false
          if (res.status) {
            if (res.data.data.resource === 'external') {
              this.loadShipping()
            } else {
              this.shippingList = res.data.data.data || []
              if (this.shippingList.length) {
                this.shippingSelected = parseInt(this.shippingList[0].id)
              }
            }
          }
        })
      }
    },
    LoadBookingCode (bkoid) {
      if (bkoid) {
        const splitted = bkoid.split('|-|')
        return {
          booking_code: (splitted[0] || ''),
          order_id: splitted.length > 1 ? (splitted[1] || '') : ''
        }
      }
      return null
    },
    ControlQuantityMerchandiseAdditional (type, operation) {
      if (type === 'merchandise' && operation === 'minus' && this.qtySelectedMerchandiseProduct === 0) {
        this.qtySelectedMerchandiseProduct = this.qtySelectedMerchandiseProduct - 1
      }

      if (type === 'merchandise' && operation === 'plus') {
        this.qtySelectedMerchandiseProduct = this.qtySelectedMerchandiseProduct + 1
      }
    }
  },
  destroyed () {
    // document.body.removeAttribute('class')
  }
}
</script>

<style lang="scss">
.v-text-field__slot,
.v-select__slot {
  font-size: 12px!important;
}
.v-select__selections {
  .v-select__selection {
    width: 100%!important;
  }
}
@page { margin: 0; }
body { margin: 0 }
.print-sheet {
  margin: 0;
  // overflow: hidden;
  position: relative;
  box-sizing: border-box;
  page-break-after: avoid;
  color: #555;
  &.print-label {
    box-shadow: unset !important;
    // height: 100% !important;
  }
}
.print-label {
  // height: 100%!important;
}

/** Paper sizes **/
body.A3           .print-sheet { width: 297mm; }
body.A3.landscape .print-sheet { width: 420mm; }
body.A4           .print-sheet { width: 210mm; }
body.A4.landscape .print-sheet { width: 297mm; }
body.A5           .print-sheet { width: 148mm; }
body.A5.landscape .print-sheet { width: 210mm; }

/** Padding area **/
.print-sheet.padding-10mm { padding: 10mm }
.print-sheet.padding-15mm { padding: 15mm }
.print-sheet.padding-20mm { padding: 20mm }
.print-sheet.padding-25mm { padding: 25mm }

/** For screen preview **/
@media screen {
  body {
    background: #e0e0e0;
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  .print-sheet {
    background: white;
    box-shadow: 0 .5mm 2mm rgba(0,0,0,.3);
    margin: 0mm;
  }
}

/** Fix for Chrome issue #273306 **/
@media print {
  //          body.A3.landscape { width: 420mm }
  // body.A3, body.A4.landscape { width: 297mm }
  // body.A4, body.A5.landscape { width: 210mm }
  // body.A5                    { width: 148mm }
  .label-print {
    svg {
      &.vue-barcode-element {
        width: 325px !important;
        height: 120px !important;
      }
    }
  }
}

// @media print and (orientation: landscape) {
//   body.A5                    { height: 148mm }
// }

$invoiceBorder: #c6893f;
.invoice-new {
  position: relative;
  min-height: 400px;
  // width: 440px;
  margin: 0 auto;
  &::before {
    display: none;
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: .05;
    background-image: url(/icon.png);
    background-size: 200px;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;
    // transform: rotate(-5deg);
  }
  ul {
    li {
      &::marker {
        opacity: 0;
      }
    }
  }
  .invoice-new-area {
    padding: 20px;
    border-top: solid 10px $invoiceBorder;
    z-index: 2;
    &.stock-in-purchase {
      border-top: solid 10px rgb(226, 25, 92);
    }
    &.stock-in {
      border-top: solid 10px rgb(7, 81, 192);
    }
    &.stock-out {
      border-top: solid 10px rgb(196, 6, 6);
    }
    // box-shadow: 10px -10px 0px 0px $invoiceBorder;
    .logo {
      height: 35px;
    }
    .buble {
      width: 100%;
      padding-top: 5px;
      padding-left: 0px;
      font-size: 40px;
      color: #c6893f;
      // span {
      //   display: inline-block;
      //   border-radius: 50%;
      //   height: 5px;
      //   width: 5px;
      //   margin-right: 10px;
      //   background: #c6893f;
      // }
    }
  }
  .invoice-new-content {
    font-size: 14px;
    table {
      width: 100%;
      font-size: 11px;
      // border-bottom: solid 1px #f7efe5;
      thead {
        td {
          padding-bottom: 3px;
          font-size: 10px;
          border-bottom: solid 1px #f0f0f0;
        }
      }
      tbody {
        td {
          &.subtotal,
          &.total {
            // background: #fdfbf7;
            &.bt {
              border-top: solid 1px #f7efe5;
            }
            &.bb {
              // border-bottom: solid 1px #f7efe5;
              font-weight: 600;
              &.bt {
                padding: 5px 0px 8px 0px;
                // background: #f5f2ef;
              }
            }
          }
        }
      }
    }
  }
  .footer-note {
    position: relative;
    bottom: 3mm;
    left: 0px;
    width: 100%;
    text-align: center;
    font-size: 9px;
    // border-top: solid 2px #eeedec;
    color: #777675;
    padding-top: 3mm;
    // font-weight: 600;
  }
  * {
    color: #000000 !important;
    &.v-chip__content {
      color: #FFFFFF !important;
    }
  }
}
.invoice-new {
  input,
  textarea,
  select {
    outline: none;
    background-color: #f0f0f0;
    border-radius: 4px;
    font-size: 10px;
    padding: 2px 4px;
  }
}
.label-print {
  padding: 20px;
  >div {
    border: solid 1px rgba(64, 33, 3, 0.7);
    padding: 20px;
    border-radius: 10px;
    min-height: 100mm;
    @media print {
      border: solid 1px #000!important;
      border-radius: 0px;
    }
    .label-header {
      font-size: 12px;
    }
    .logo {
      width: 90%;
    }
    .full-address {
      font-size: 12px;
    }
    * {
      line-height: 130%!important;
    }
  }
}
</style>
